.dx-widget, .dx-overlay-wrapper {
  font-family: Pretendard, sans-serif;
}

/* popover */
.dx-popup-wrapper > .dx-overlay-content {
  border-radius: 8px;
  box-shadow: 0px 12px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB33;
  -webkit-box-shadow: 0px 12px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB33;
}

.dx-popup-content {
  padding: 8px 0;
}

.dx-popup-content button {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #F4F6F8 transparent transparent transparent;
}
.dx-popup-content button:first-child {
  border: none;
}
.dx-popup-content button:hover,
.dx-popup-content button:active {
  background-color: #919EAB14;
}

/* grid */
.dx-datagrid {
  color: #1C252E;
}

.dx-datagrid-headers {
  border-bottom: none;
  color: #637381;
  background-color: #F4F6F8;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-bottom: none;
  font-weight: 600;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit):not(.dx-datagrid-group-space) {
  background-color: transparent;
}

.dx-datagrid .dx-row > td, 
.dx-datagrid .dx-row > tr > td,
.dx-datagrid .dx-datagrid-table .dx-header-row > td {
  padding: 0 7px;
}

.dx-datagrid .dx-row > td, .dx-row > tr > td {
  height: 50px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle;
}

.dx-datagrid-rowsview .dx-row {
  border-top: none;
  border-bottom: 1px dashed #919EAB33;
}

.dx-datagrid .dx-row-lines > td,
.dx-datagrid-headers .dx-datagrid-content,
.dx-datagrid-rowsview .dx-row.dx-row-lines:nth-last-child(2) {
  border-bottom: none;
}

.dx-datagrid-content {
  border-bottom: 1px dashed #919EAB33;
}